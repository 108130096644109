<template>
  <span class="bible-snippet lg:relative">
    <span
      class="text-light-blue-700"
      @click="this.hover = true"
      @mouseover="this.hover = true"
      v-on:focus="this.hover = true"
      :tabindex="0"
    >
      {{ snippetText }}
    </span>
    <div
      v-if="showOnHover"
      class="snippetModal fixed left-0 top-0 z-50 lg:absolute lg:top-0 lg:pt-8"
      @mouseleave="this.hover = false"
    >
      <div
        class="biblePopout lg:border-grey-200 flex h-full min-h-screen w-screen flex-col-reverse justify-end bg-white p-6 lg:h-fit lg:min-h-0 lg:w-[472px] lg:flex-col lg:justify-start lg:rounded-xl lg:border"
      >
        <div>
          <div class="text-light-blue-700 flex items-center text-base lg:mb-3">
            <bible-icon class="mr-2 h-5 w-5" :fill-class="'fill-light-blue-700'" />
            <span>
              {{ title }}
            </span>
          </div>
          <div
            v-if="content"
            class="snippet not-prose line-clamp-15 my-3 text-base font-normal transition duration-300 ease-in-out"
            v-html="content"
          />
        </div>
        <div class="mb-5 flex items-center justify-between lg:mb-0">
          <a
            class="bg-light-blue-900 hover:bg-light-blue-700 inline-flex cursor-pointer items-center rounded-xl px-3 py-2 text-base font-medium !text-white !no-underline transition duration-300 ease-in-out"
            :href="bibleExperienceLink"
            v-on:blur="this.hover = false"
            :tabindex="0"
          >
            <bible-icon class="mr-3 h-5 w-5" :fill-class="'fill-white'" />
            <span>Read In Context</span>
          </a>
          <button class="lg:hidden" @click="this.hover = false">
            <XMarkIcon class="h-6 w-6 text-indigo-800"></XMarkIcon>
            <span class="sr-only">close</span>
          </button>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/24/solid'
import BibleIcon from '@TodayInTheWord/components/icons/bible-icon.vue'
import SLink from '@TodayInTheWord/components/shared/s-link.vue'
import DataLayerPush from '@TodayInTheWord/scripts/data-layer'

export default {
  name: 'BibleSnippet',
  components: {
    BibleIcon,
    XMarkIcon,
    SLink,
  },
  props: {
    text: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    page: {
      type: String,
      default: null,
    },
    bibleVersion: {
      type: String,
      default: 'NIV',
    },
    bibleLanguage: {
      type: String,
      default: 'English',
    },
  },
  computed: {
    bibleExperienceLink() {
      if (this.page && this.title) {
        var expLink = this.page + '?pas=' + this.title
        try {
          expLink = this.page + '?pas=' + this.title.split(':')[0]
        } catch {}
        return expLink
      }
    },
    snippetText() {
      if (this.text) {
        return this.text
      } else {
        return this.title
      }
    },
    showOnHover() {
      if (this.hover) {
        DataLayerPush({
          event: 'bible_api_call',
          event_type: 'popover',
          bible_source: 'Bible Gateway',
          bible_language: this.bibleLanguage,
          bible_version: this.bibleVersion,
          bible_osis_reference: this.title,
        })
      }
      return this.hover
    },
  },
  data() {
    return {
      hover: false,
      searchResult: false,
    }
  },
}
</script>

<script setup></script>

<style lang="scss">
div:has(> span.bible-snippet) {
  display: inline-block;
}

.bible-snippet {
  .chapternum {
    display: none;
  }

  p {
    margin: 0;
  }

  .snippet {
    .crossreference,
    .footnote {
      display: none;
    }

    * {
      display: inline;
    }
  }
}
</style>
