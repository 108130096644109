<template>
  <div class="bibleReadingControls border-grey-200 border-b">
    <div
      class="py relative mx-auto flex flex-row-reverse justify-between p-3 lg:container lg:flex-row lg:p-5"
    >
      <div v-c2-click-outside="closeVerseMenu">
        <template v-if="mediaStore.isMobile || mediaStore.isTablet">
          <MobileVerseMenu @update-content="updateContent"></MobileVerseMenu>
        </template>
        <template v-else>
          <button
            id="openBibleMenu"
            ref="openBibleButton"
            aria-haspopup="true"
            aria-controls="testamentMenu"
            @click="
              (bibleGateway.verseMenu = !bibleGateway.verseMenu), (bibleGateway.versionMenu = false)
            "
            @click.stop.prevent
            class="text-grey-500 hover:bg-grey-200 hover:text-grey-700 inline-flex cursor-pointer items-center justify-center rounded-xl border border-none bg-white px-3 py-2 font-medium transition duration-300 ease-in-out"
          >
            <template v-if="!isLoading">
              <template v-for="(book, index) in bibleGateway.reader" :key="index">
                {{ book.passage.reference }}
              </template>
            </template>
            <template v-else>
              {{ bibleGateway.activeBook }}
            </template>
            <span class="transition">
              <ChevronDownIcon
                v-if="!bibleGateway.verseMenu"
                class="ml-3 h-5 w-5"
              ></ChevronDownIcon>
              <ChevronUpIcon v-if="bibleGateway.verseMenu" class="ml-3 h-5 w-5"></ChevronUpIcon>
            </span>
          </button>
        </template>
        <div v-if="bibleGateway.verseMenu" class="container absolute left-0 top-full z-40">
          <div
            class="border-grey-300 mt-4 flex flex-col space-y-2 rounded-xl border bg-white p-8"
            id="testamentMenu"
          >
            <div v-for="(menu, index) in bibleGateway.booksMenu" :key="index">
              <div v-for="(section, i) in menu.sections" :key="i">
                <div v-if="section.name === bibleGateway.selectedSection" class="flex gap-x-14">
                  <div
                    class="flex w-1/4 flex-col space-y-2"
                    aria-controls="bookMenu"
                    role="menuitem"
                  >
                    <div v-for="(section, j) in menu.sections" :key="j">
                      <button
                        :id="'testament' + (j + 1)"
                        role="menuitem"
                        aria-haspopup="true"
                        aria-controls="bookMenu"
                        :class="
                          section.name === bibleGateway.selectedSection
                            ? 'border-grey-200 bg-grey-200'
                            : 'border-white bg-white'
                        "
                        class="text-grey-800 hover:border-grey-200 hover:bg-grey-200 inline-flex w-full cursor-pointer items-center justify-start rounded-xl border px-3 py-2 font-medium transition-all"
                        @click="bibleGateway.selectedSection = section.name"
                        @keydown.tab.prevent="focusBooks()"
                        @keydown.right.prevent="focusBooks()"
                        @click.stop.prevent
                      >
                        {{ section.display }}
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="section.name === bibleGateway.selectedSection"
                    class="grid w-1/3 grow auto-cols-fr grid-cols-3 gap-x-5 gap-y-2"
                    id="bookMenu"
                    aria-controls="chapterMenu"
                  >
                    <button
                      v-for="(book, j) in section.books"
                      :id="'book' + (j + 1)"
                      :key="j"
                      aria-haspopup="true"
                      aria-controls="chapterMenu"
                      class="flex basis-1/4 whitespace-nowrap text-sm transition ease-in hover:text-cyan-700"
                      :class="
                        bibleGateway.selectedBook === book.display
                          ? 'font-medium text-cyan-700'
                          : 'text-grey-700'
                      "
                      @click="bibleGateway.selectedBook = book.display"
                      @keydown.right.prevent="focusChapters()"
                      @keydown.left.prevent="focusTestaments()"
                      @click.stop.prevent
                    >
                      {{ book.display }}
                    </button>
                  </div>
                  <div class="flex w-1/4 xl:w-5/12">
                    <div v-for="(book, j) in section.books" :key="j">
                      <div
                        v-if="bibleGateway.selectedBook === book.display"
                        class="flex flex-wrap"
                        id="chapterMenu"
                        aria-activedescendant="chapter1"
                      >
                        <button
                          v-for="(chapter, k) in book.chapters"
                          :id="'chapter' + (k + 1)"
                          role="menuitem"
                          :key="k"
                          class="hover:text-light-blue-500 flex h-8 w-8 cursor-pointer items-center justify-center text-sm transition-all ease-in"
                          :class="
                            chapter.isActive
                              ? 'bg-light-blue-100 text-light-blue-900 rounded-xl'
                              : 'bg-white'
                          "
                          @click="
                            (bibleGateway.passage = book.name + chapter.name),
                              (bibleGateway.activeBook = bibleGateway.passage.reference),
                              (bibleGateway.verseMenu = false),
                              (bibleGateway.activeBook = book.display),
                              updateContent(false)
                          "
                          @keydown.left.prevent="focusBooks()"
                        >
                          {{ chapter.name }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-c2-click-outside="closeVersionMenu" class="relative">
        <div>
          <template v-if="mediaStore.isMobile || mediaStore.isTablet">
            <MobileVersionMenu @update-content="updateContent"></MobileVersionMenu>
          </template>
          <template v-else>
            <button
              @click="
                (bibleGateway.versionMenu = !bibleGateway.versionMenu),
                  (bibleGateway.verseMenu = false)
              "
              aria-haspopup="true"
              aria-controls="versionMenu"
              class="text-grey-500 hover:bg-grey-200 hover:text-grey-700 inline-flex cursor-pointer items-center justify-center rounded-xl border-solid bg-white !px-3 py-2 font-medium transition duration-300 ease-in-out marker:border-none"
              :emphasis="'outline'"
              @click.stop.prevent
            >
              {{ bibleGateway.activeVersionDisplay }}
              <ChevronDownIcon v-if="!bibleGateway.versionMenu" class="ml-3 h-5 w-5">
              </ChevronDownIcon>
              <ChevronUpIcon v-if="bibleGateway.versionMenu" class="ml-3 h-5 w-5"> </ChevronUpIcon>
            </button>
          </template>
        </div>
        <div
          v-if="bibleGateway.versionMenu"
          id="versionMenu"
          class="border-grey-300 absolute right-0 top-full z-40 mt-4 flex w-max flex-col space-y-2 rounded-xl border bg-white p-8"
        >
          <div v-for="(menu, index) in bibleGateway.versionsMenu" :key="index">
            <button
              class="transition duration-300 ease-in-out hover:text-cyan-700"
              :class="menu.name === bibleGateway.activeVersion ? 'text-cyan-700' : 'text-grey-700'"
              @click="
                (bibleGateway.versionMenu = false),
                  bibleGateway.setActiveVersion(menu),
                  updateContent(true)
              "
              @click.stop.prevent
            >
              {{ menu.display }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <main
    class="bibleReadingWrapper mx-auto flex min-h-[70vh] flex-col-reverse p-5 lg:container lg:mt-14 lg:flex-row"
    style="--scrollOffset: 0px; --wrapperOffset: 0px"
  >
    <aside class="mt-24 w-full lg:m-0 lg:w-1/3">
      <div v-if="bibleGateway.relatedResources && bibleGateway.relatedResources.length > 0">
        <h4 class="text-md text-grey-900 mb-3 line-clamp-1 font-semibold md:text-xl md:font-medium">
          {{ model.relatedResourcesLabel }}
        </h4>
        <s-link
          class="line-clamp-2"
          v-for="(link, idx) in bibleGateway.relatedResources"
          :key="idx"
          :href="link.href"
          :external-link="link.isExternal"
          :target="link.target"
        >
          {{ link.text }}
        </s-link>
      </div>
    </aside>
    <div v-if="isLoading" class="w-full lg:w-2/3">
      <SkeletonLoader>
        <BibleReaderSvg class="bg-stretch"></BibleReaderSvg>
      </SkeletonLoader>
    </div>
    <div v-if="!isLoading" class="relative w-full lg:w-2/3">
      <div
        class="lg:border-grey-300 flex justify-center lg:rounded-xl lg:border"
        :class="{
          static: stickyMobileControls,
          relative: !stickyMobileControls,
        }"
      >
        <div v-for="(book, index) in bibleGateway.reader" :key="index" class="md:p-5 lg:p-8">
          <template v-if="mediaStore.isMobile || mediaStore.isTablet">
            <div v-if="book.passage.reference" class="text-lg font-medium" id="bookAndChapter">
              {{ book.passage.reference }}
            </div>
            <div
              class="mobileControls bottom-0 left-0 z-[39] flex bg-white px-5 py-1"
              :class="[
                book.previous ? 'justify-between' : 'justify-end',
                stickyMobileControls ? 'absolute w-full px-0' : 'fixed w-screen px-5',
              ]"
            >
              <button
                v-if="book.previous"
                class="previousChapter flex items-center justify-center"
                @click="
                  (bibleGateway.passage = book.previous),
                    (bibleGateway.activeVersion = book.passage.attribution.translation),
                    updateContent(false),
                    (stickyMobileControls = false)
                "
              >
                <ArrowLeftIcon class="text-light-blue-900 h-6 w-6"></ArrowLeftIcon>
                <span class="text-light-blue-900 ml-8"> Previous </span>
              </button>

              <button
                v-if="book.next"
                class="nextChapter previousChapter flex items-center justify-center"
                @click="
                  (bibleGateway.passage = book.next),
                    (bibleGateway.activeVersion = book.passage.attribution.translation),
                    updateContent(false),
                    (stickyMobileControls = false)
                "
              >
                <span class="text-light-blue-900 mr-8"> Next </span>
                <ArrowRightIcon class="text-light-blue-900 h-6 w-6 lg:text-white"></ArrowRightIcon>
              </button>
            </div>
          </template>
          <template v-else>
            <button
              v-if="book.previous"
              class="previousChapter translate-yp-2 lg:bg-light-blue-900 lg:hover:bg-light-blue-700 absolute left-0 flex items-center justify-center transition duration-300 ease-in-out max-lg:-bottom-8 lg:top-1/2 lg:h-10 lg:w-10 lg:-translate-x-1/2 lg:rounded-lg"
              @click="
                (bibleGateway.passage = book.previous),
                  (bibleGateway.activeVersion = book.passage.attribution.translation),
                  updateContent(false)
              "
              style="top: min(calc(10em + var(--scrollOffset)), 90%)"
            >
              <ArrowLeftIcon class="text-light-blue-900 h-6 w-6 lg:text-white"></ArrowLeftIcon>
              <span class="text-light-blue-900 ml-8 lg:sr-only"> Previous </span>
            </button>
            <div v-if="book.passage.reference" class="text-lg font-medium" id="bookAndChapter">
              {{ book.passage.reference }}
            </div>
            <button
              v-if="book.next"
              class="nextChapter translate-yp-2 lg:bg-light-blue-900 lg:hover:bg-light-blue-700 absolute right-0 flex items-center justify-center transition duration-300 ease-in-out max-lg:-bottom-8 lg:top-1/2 lg:h-10 lg:w-10 lg:translate-x-1/2 lg:rounded-lg"
              @click="
                (bibleGateway.passage = book.next),
                  (bibleGateway.activeVersion = book.passage.attribution.translation),
                  updateContent(false)
              "
              style="top: min(calc(10em + var(--scrollOffset)), 90%)"
            >
              <span class="text-light-blue-900 mr-8 lg:sr-only"> Next </span>
              <ArrowRightIcon class="text-light-blue-900 h-6 w-6 lg:text-white"></ArrowRightIcon>
            </button>
          </template>

          <div class="bibleReading" v-if="book.passage.content" v-html="book.passage.content" />
        </div>
      </div>
      <div class="mb-8 text-xs md:p-5 lg:p-8" v-if="bibleGateway.activeVersionCopyright">
        <div class="text-center" v-html="bibleGateway.activeVersionCopyright"></div>
        <div class="relative z-50 flex justify-center pt-2" v-if="model.copyrightImage">
          <div v-if="model.copyrightLink">
            <s-link
              :href="model.copyrightLink.href"
              :target="model.copyrightLink.target"
              :external-link="model.copyrightLink.isExternal"
            >
              <shared-image
                :model="model.copyrightImage"
                format="?width=175&height=23&format=webp&compand=true"
              />
            </s-link>
          </div>
          <div v-else>
            <shared-image
              :model="model.copyrightImage"
              format="?width=175&height=23&format=webp&compand=true"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/solid'
import BibleReaderSvg from '@TodayInTheWord/components/icons/bible-skeleton.vue'
import MobileVerseMenu from '@TodayInTheWord/components/pages/bible-reading-experience/components/mobile-verse-menu.vue'
import MobileVersionMenu from '@TodayInTheWord/components/pages/bible-reading-experience/components/mobile-version-menu.vue'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import SkeletonLoader from '@TodayInTheWord/components/shared/skeleton-svg.vue'
import { useBibleGatewayStore } from '@TodayInTheWord/stores/bible-gateway'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import vC2ClickOutside from 'uikitdirectives/c2-click-outside'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const mediaStore = useMediaSizeStore()
const model = useEpiPageContextStore().model
const bibleGateway = useBibleGatewayStore()
const openBibleButton = ref(null)
const router = useRouter()
const route = useRoute()
const stickyMobileControls = ref(false)

let scrollOffset = '--scrollOffset:0px;'
let wrapperOffset = '--wrapperOffset:0px;'
function closeVerseMenu() {
  if (bibleGateway.verseMenu) {
    bibleGateway.verseMenu = false
  }
}

function closeVersionMenu() {
  if (bibleGateway.versionMenu) {
    bibleGateway.versionMenu = false
  }
}

const updateQueryParams = () => {
  const newQuery = { ...route.query }
  bibleGateway.passage && (newQuery.pas = bibleGateway.passage)
  bibleGateway.activeVersion && (newQuery.ver = bibleGateway.activeVersion)

  window.history.replaceState({}, '', `?${new URLSearchParams(newQuery).toString()}`)
}

const updateContent = async (fetchmenu) => {
  bibleGateway.isLoading = true
  await bibleGateway.fetchContent(fetchmenu)

  updateQueryParams()

  bibleGateway.isLoading = false
}

onMounted(async () => {
  if (router.currentRoute.value.query.pas) {
    bibleGateway.passage = router.currentRoute.value.query.pas
  }
  if (router.currentRoute.value.query.ver) {
    bibleGateway.activeVersion = router.currentRoute.value.query.ver
  }

  bibleGateway.initStore(model)

  if (window) {
    window.addEventListener('resize', () => {
      bibleGateway.verseMenu = false
      bibleGateway.versionMenu = false
    })
    window.addEventListener('scroll', calculateScrollOffset)
    window.addEventListener('keydown', handleKeypress)
  }
})
onUnmounted(() => {
  window.removeEventListener('scroll', calculateScrollOffset)
  window.removeEventListener('keydown', handleKeypress)
})

const calculateScrollOffset = (event) => {
  const wrapper = document.getElementsByClassName('bibleReadingWrapper')[0]
  const controls = document.getElementsByClassName('mobileControls')[0]

  scrollOffset = '--scrollOffset:' + window.scrollY + 'px;'
  wrapperOffset =
    '--wrapperOffset:' +
    (document.getElementsByClassName('bibleReadingWrapper')[0].getBoundingClientRect().top +
      window.scrollY) +
    'px;'
  wrapper.style = scrollOffset + wrapperOffset

  if (controls) {
    if (wrapper.getBoundingClientRect().bottom <= window.innerHeight) {
      stickyMobileControls.value = true
    } else {
      stickyMobileControls.value = false
    }
  }
}

let lastcheck = ''

function focusTestaments() {
  try {
    changeFocus('testament1')
  } catch {}
}

function focusBooks() {
  try {
    changeFocus('book1')
  } catch {}
}

function focusChapters() {
  try {
    changeFocus('chapter1')
  } catch {}
}

function changeFocus(id, timeout) {
  setTimeout(() => {
    let target = document.getElementById(id)
    if (target !== null) {
      lastcheck = null
      target.focus()
    } else {
      if (id == lastcheck) {
        changeFocus(id, timeout)
      }
    }
  }, timeout)
}
function handleKeypress(e) {
  if (e.key == 'Escape') {
    document.getElementById('openBibleMenu').focus()
    closeVerseMenu()
  }
}

const isLoading = computed(() => {
  return bibleGateway.isLoading
})
</script>

<style lang="scss">
p {
  margin-bottom: 20px;

  .chapternum {
    font-size: 2em;
    font-weight: 800;
  }
}
.bibleReading {
  min-height: 55vh;

  .versenum,
  span.chapternum {
    font-weight: 700;
  }

  span.chapternum {
    font-size: 24px;
    font-size: 2.4rem;
    position: relative;
    line-height: 0.8em;
    bottom: -0.1em;
    left: 0;
    text-indent: 0;
  }

  p.chapter-3 span.chapternum {
    font-size: 16px;
    font-size: 1.6rem;
    bottom: 0;
  }

  .woj {
    color: #e50000;
  }

  .woj .chapternum {
    color: #4f0d00;
  }

  div,
  h3,
  p {
    .left-1,
    .left-2,
    .left-3,
    .left-4 {
      position: relative;
    }
  }

  .left-1 {
    margin-left: 1em;
  }

  .left-2 {
    margin-left: 2em;
  }

  .left-3 {
    margin-left: 3em;
  }

  .left-4 {
    margin-left: 4em;
  }

  .hang-1 {
    margin-left: 1em;
    text-indent: -1em;
  }

  .hang-2 {
    margin-left: 2em;
    text-indent: -2em;
  }

  .hang-3 {
    margin-left: 3em;
    text-indent: -3em;
  }

  .hang-4 {
    margin-left: 4em;
    text-indent: -4em;
  }

  .hang-5 {
    margin-left: 5em;
    text-indent: -5em;
  }

  .hang-6 {
    margin-left: 6em;
    text-indent: -6em;
  }

  .right-1 {
    margin-right: 1em;
  }

  .right-2 {
    margin-right: 2em;
  }

  .right-3 {
    margin-right: 3em;
  }

  .right-4 {
    margin-right: 4em;
  }

  .poetry {
    margin-left: 1em;
    position: relative;
    padding-left: 2.6em;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .indent-1 {
    padding-left: 2em;
  }

  h3 {
    font-weight: 700;
    font-size: 1.1em;
    padding-bottom: 0.5em;
  }

  h4 {
    font-weight: 700;
    font-size: 1.2em;
  }

  h3.chapter {
    display: block;
  }

  h3 sup.footnote {
    font-size: 0.71em;
    font-weight: 400;
  }

  .crossreference,
  .footnote {
    display: none;
  }

  .verse {
    margin-top: 1em;
    margin-bottom: 0;
  }

  .center {
    text-align: center;
  }

  .selah {
    font-style: italic;
    margin-left: 2em;
  }

  sup {
    font-size: 0.625em;
    line-height: normal;
    top: auto;
  }

  .sup,
  .versenum,
  .versenum span,
  sup,
  sup span {
    vertical-align: text-top;
  }

  sup {
    top: 0.15em;
  }

  sup a {
    vertical-align: unset;
  }

  sup.versenum.opening {
    vertical-align: baseline;
    font-size: 1.6rem;
    position: static;
  }

  .double-underline {
    text-decoration: underline;
    border-bottom: 1px solid;
  }

  .underline {
    text-decoration: underline;
  }

  .crossrefs li,
  .crossrefs ol {
    list-style-type: upper-alpha;
  }

  span.father-speech {
    color: #0b5792;
  }

  span.spirit-speech {
    color: #dfa926;
  }

  u.father-speech {
    text-decoration: none;
    border-bottom: 1px solid #0b5792;
  }

  u.jesus-speech {
    text-decoration: none;
    border-bottom: 1px solid #b34b2b;
  }
}
</style>
